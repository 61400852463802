<template>
  <el-dialog title="添加用户" width="80%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="b_t b_r b_l b_bot">
          <div class="flex_l_c b_b p_all1">
            <el-input style="width: 60%" class="m_r1" maxlength="66" size="small" v-model="searchStr"
              placeholder="请输入关键字进行搜索" clearable></el-input>
            <el-button type="primary" @click="searchRefreshList()" size="small" icon="el-icon-search">搜索</el-button>
          </div>
          <div class="flex_b_c b_b" v-for="(item, index) in userList" :key="item.id">
            <div class="p_all1">
              <span class="el-icon-user-solid m_r1"></span>
              <span>{{ item.name }}</span>
            </div>
            <div class="p_all1 b_l">
              <el-button type="primary" @click="toLeft(item, index, 0)" size="mini" icon="el-icon-right"></el-button>
            </div>
          </div>
        </div>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-col>
      <el-col :span="12">
        <div class="b_t b_r b_l">
          <div class="b_b" style="padding: 15px">用户列表</div>
          <div class="flex_b_c b_b p_all1" v-for="(item, index) in authorityList" :key="item.id">
            <div>
              <span class="el-icon-user-solid m_r1"></span>
              <span>{{ item.name }}</span>
            </div>
            <div>
              <el-tag type="success" v-show="item.head == 1" style="margin-right: 10px">策展人</el-tag>
              <el-button v-show="item.head == 0" size="mini" type="text" @click="setHead(item)">设为策展人</el-button>
              <!--                            <el-button size="mini" :type="item.head==1 ? 'text' : 'success'" plain @click="setHead(item)">{{item.head==1 ? '策展人':'设为策展人'}}</el-button>-->
              <el-button icon="el-icon-delete" size="mini" type="danger" @click="toLeft(item, index, 1)">删除</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      searchStr: '',
      userList: [],
      authorityList: [],
      files: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
    }
  },
  methods: {
    init(file) {
      this.files = file;
      this.refreshList();
      this.exhibitionAuthority();
      this.visible = true
    },
    searchRefreshList(modular) {
      this.pageNo = 1
      this.refreshList(modular)
    },
    refreshList() {
      this.loading = true
      this.$axios("zyd-user/sysuser/queryUserList", {
        'current': this.pageNo,
        'size': this.pageSize,
        "name": this.searchStr,
        "loginName": ''
      }, 'get').then(data => {
        if (data && data.status) {
          this.userList = data.data.records
          this.total = parseInt(data.data.total)
          this.loading = false
        }
      })
    },
    exhibitionAuthority() {
      this.loading = true
      this.$axios(this.api.zlzs.selectSysExhibitionAuthorityAll, {
        "exhibitionId": this.files.id
      }, 'get').then(data => {
        if (data && data.status) {
          this.authorityList = data.data
        }
      })
    },
    // 移到右边
    toLeft(item, index, type) {
      if (type == 0) {
        let pd = false
        this.authorityList.forEach(function (v) {
          if (item.id == v.id) {
            pd = true
          }
        })
        if (pd) {
          this.$message.error("已经在用户列表")
          return false
        }
        // this.userList.splice(index, 1)
        this.$set(item, "head", 0)
        this.authorityList.push(item)
      } else {
        this.authorityList.splice(index, 1)
        // this.userList.push(item)
      }

    },
    //设为策展人
    setHead(item) {
      this.authorityList.forEach(function (v) {
        if (v.id == item.id) {
          item.head = 1
        } else {
          v.head = 0
        }
      })
      // console.log(this.authorityList);
    },
    // 保存
    doSubmit() {
      let userIds = this.authorityList.map(item => {
        return item.id
      }).join(',')

      let heads = this.authorityList.map(item => {
        return item.head
      }).join(',')
      this.$axios(this.api.zlzs.save, {
        "exhibitionId": this.files.id,
        "userIds": userIds,
        "heads": heads
      }, "post").then(data => {
        this.loading = false
        if (data && data.status) {
          this.$message.success(data.msg)
          this.$emit('refreshDataList')
          this.visible = false
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
  }
}
</script>

<style scoped>
/*.el-button.el-button--text.is-plain:focus, .el-button.el-button--text.is-plain:hover {*/
/*    border: none !important;*/
/*}*/
</style>
